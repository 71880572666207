import React from 'react';
import { Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import { Link, graphql } from 'gatsby';
import { MdxArticle } from '../../types/Article';
import Content from '../../layouts/main';
import styled from '../../styled';
import { SEO } from '../../components/SEO';
import { PostLink } from '../../components/PostLink';

const Heading = styled('span')`
  display: inline-block;
  color: #4f4f4f;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
`;

const PostContainer = styled('div')`
  ${({ theme }) => css`
    max-width: 1200px;
    padding: ${theme.spacing(2)}px;
    margin: ${theme.spacing(4)}px auto;
  `};
`;

const Hero = styled('div')`
  padding: 120px 16px 96px;
  color: white;
  background-color: #1c2126;
  text-align: center;

  a {
    display: inline-block;
    margin-top: 36px;
    font-weight: 700;
  }

  @media (max-width: 720px) {
    padding: 50px 16px 16px;
  }
 }
`;

const H1 = styled('h1')`
  font-size: 60px;
  font-weight: 700px;
  text-align: center;
  line-height: 60px;

  @media (max-width: 720px) {
    font-size: 36px;
  }
`;

type PageQueryData = {
  allMdx: {
    edges: {
      node: MdxArticle;
    }[];
  };
};

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  margin-bottom: ${(p) => p.theme.spacing(8)}px;

  & > div {
    margin-bottom: 12px;
  }

  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;

const HeadingWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

const IndexPage = ({
  data: {
    allMdx: { edges }
  }
}: {
  data: PageQueryData;
}) => {
  const livePosts = edges
    .filter((edge) => !!edge.node.frontmatter.date)
    .map((edge) => edge.node);

  return (
    <Content>
      <>
        <SEO
          title="Affiliate Marketing Blog"
          description="Keep up with the latest news on how to improve your affiliate marketing income with data-driven strategies."
          pathname="/blog/all/"
        />
        <Hero>
          <H1>All Posts</H1>
          <div style={{ maxWidth: '800px', margin: '0 auto' }}>
            <Typography variant="h6" component="p">
              All the articles ever published on our blog.
            </Typography>
          </div>
          <Link to="/blog/">← Back to the blog</Link>
        </Hero>
        <PostContainer>
          <HeadingWrapper>
            <Heading>Latest Articles</Heading>
          </HeadingWrapper>
          <Grid>
            {livePosts.map((post) => (
              <PostLink key={post.fields.slug} post={post} />
            ))}
          </Grid>
        </PostContainer>
      </>
    </Content>
  );
};

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            category
            summary
            image
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;
